<template>
  <div class="mainsheet">
    <el-card>
      <div class="bidding">
        <div class="main">
          <!-- 商品展示 -->
          <div class="bigbox">
            <div class="item " v-for="(i,index) in deptlist" :key="index" @click="gotoDetails(i)">
              <div class="itemtitle">
                <el-image style="width: 210px; height: 150px;margin:5px;" :src="myPic(i.thumb)" fit="fill"
                          lazy></el-image>
              </div>
              <div class="itembody gaizhang1">
                <div style=" text-align: center;width:90%"><span :title="i.targetname">{{i.targetname}}</span></div>
                <div class="pricex" style="margin-left:5%">
                  <span>挂牌价：</span>
                  <span></span>
                  <span>{{i.price}}</span>
                  <span style="font-size:12px">{{myUnit(i.unit)}}</span>
                </div>
                <div class="pricex" style="margin-left:5%">
                  <span>成交价：</span>
                  <span></span>
                  <span>{{i.outbidprice}}</span>
                  <span style="font-size:12px">{{myUnit(i.unit)}}</span>
                </div>
                <div class="pricex" style="margin-left:5%">
                  <span>结束时间：{{(i.finalendtime).substring(0,10)}}</span>
                  <span style="font-size:1px"></span>
                  <span style="font-size:1px"></span>
                  <span style="font-size:2px"></span>
                </div>
                <div class="numx">
                  <!-- <span >所在地</span> -->
                  <span>{{i.areaname}}</span>
                </div>
              </div>
              <!-- <div class="itemfoot">
                 <div class="status " :class="myState(i.outbidstate)"  style="background:red">{{typeName(i.outbidstate)}}</div>
                <div class="times">{{myTime(i)}}</div>
              </div> -->
            </div>
          </div>
          <!-- 商品展示 -->
          <div class="block" align="right">
            <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageIndex"
                :page-sizes="[5]"
                :page-size="pageSize"
                :total="totalPage"
                class="mt-2"
                layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- <div class="swiper-container" id="banner">
           <div class="swiper-wrapper" >
             <div class="swiper-slide" v-for="(i,index) in listdata" :key="index" @click="gotoDetails(i)">
                <div>
                    <div v-if="myPic2(i.thumb)">
                         <img :src="$ImgUrl+ i.thumb[0]" width="100%" height="130px"/>
                    </div>
                </div>
                <div>
                    {{i.targetname}}
                </div>
                <div class="gaizhang1 gaizhang2">
                    <div>挂牌价：{{i.price}}{{(i.unit)}}</div>
                    <div>成交价：{{i.outbidprice}}{{(i.unit)}}</div>
                    <div>标的所在地：{{i.areaname}}</div>
                    <div>结束时间：{{i.finalendtime}}</div>
                </div>
             </div>
         </div>
         <div class="swiper-button-next"></div>
         <div class="swiper-button-prev"></div>
         <div class="swiper-pagination"></div>
     </div> -->
    </el-card>
  </div>
</template>
<script>
  import Swiper from "swiper";
  import {mapGetters} from "vuex";
  
  export default {
    data () {
      return {
        activedInterval: null, // 定时器
        icaddr: require ('../assets/imgs/ic_address.png'),
        deptlist: [],
        activeNames: ['1'],
        //分页数据
        dataForm: {
          productid: '',
          delivery: '',
          outbidstate: '',
          splitstate: ''
        },
        remaining: 0,
        systemTime: "", //获取服务器时间
        dataList: [],
        pageIndex: 1,
        pageSize: 5,
        totalPage: 1,
        dataListLoading: false,
        qualityTableStyle: {
          'background': 'whitesmoke',
          'font-weight': 'bold',
          'font-size': ' 12px',
          'font-family': "Microsoft YaHei",
          'color': '#333333',
          'text-align': 'center',
        },
        bidnames: '',//竞价场次名称
        timeDate: '',//开始结束时间
        listdata: []
      }
    },
    activated () {
    
    
    },
    mounted () {
      this.getDataList ();
      new Swiper (".swiper-container", {
        slidesPerView: 4,
        spaceBetween: 20,
        slidesPerGroup: 4,
        loopFillGroupWithBlank: true,
        // paginationClickable :true,
        // centeredSlides: true,
        // slidesPerColumn: 2, //设置多行布局里面每列的slide数量
        // slidesPerColumnFill: "row", //多行布局中以什么形式填充
        //启动动态检查器(OB/观众/观看者)，当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper。
        //可以一页  slidesPerView 个div。
        observer: true,
        // observeSlideChildren: true,
        loop: true, //禁止循环
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    methods: {
      ...mapGetters (["getUser", "getToken"]),
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        //调用查询信息接口
        this.getDataList ()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        //调用查询信息接口
        this.getDataList ()
      },
      // 单位转换
      unitCk (e) {
        return e;
        // if(e == '11'){
        //     return '元/吨'
        // }
        // return '元'
      },
      myPic (e) {
        if (e.length > 0) {
          return this.$ImgUrl + e[0]
        }
        return require ('../assets/imgs/cart-empty.png')
      },
      myPic2 (e) {
        if (e.length > 0) {
          return true
        }
        return false
      },
      // 获取数据列表
      getDataList () {
        this.$fetch ('/shoppingui/bidShopping/getResultSucces', {
          'page': this.pageIndex,
          'limit': this.pageSize,
        }, true).then ((data) => {
          if (data && data.code === 0) {
            this.listdata = data.data.list
            this.deptlist = data.data.list
            this.totalPage = data.data.total
          } else {
            this.dataList = []
            this.totalPage = 0
            this.$message ({
              message: data.msg,
              type: 'error',
              showClose: 'true'
            })
          }
        })
      },
      // 成功案例跳转详情
      gotoDetails (e) {
        let queryData = {};
        this.$set (queryData, 'id', e.targetid)
        this.$set (queryData, 'targetid', e.targetid)
        this.$set (queryData, 'memberid', this.getUser ().id)
        let routeData = this.$router.resolve ({path: "/auction", query: queryData});
        window.open (routeData.href, '_blank');
      },
      opennewbidding () {
        let routeData = this.$router.resolve ({path: "/newbidding"});
        window.open (routeData.href, '_blank');
      },
      iteminfo (e) {
        let queryData = {};
        this.$set (queryData, 'id', e.id)
        this.$set (queryData, 'targetid', e.id)
        this.$set (queryData, 'memberid', this.getUser ().id)
        this.$router.push ({path: "/auction", query: queryData});
      },
      myState (state) {
        if (state == 1) {
          return 'redc'
        }
        if (state == 0) {
          return 'redc'
        }
        if (state == 2) {
          return 'blankc'
        }
        if (state == 3) {
          return 'blankc'
        }
        if (state == 4) {
          return 'blankc'
        }
        if (state == 5) {
          return 'greenc'
        }
        
      },
      myTime (i) {
        if (i.outbidstate == 1) {
          return '开始时间:' + i.begintime
        }
        if (i.outbidstate == 0) {
          return '开始时间:' + i.begintime
        }
        if (i.outbidstate == 2) {
          return i.finalendtime
        }
        if (i.outbidstate == 3) {
          return '结束时间:' + i.finalendtime
        }
        if (i.outbidstate == 4) {
          return '结束时间:' + i.finalendtime
        }
        if (i.outbidstate == 5) {
          return '开始时间:' + i.begintime
        }
      },
      typeName (state) {
        if (state == 1) {
          return '竞价中'
        }
        if (state == 0) {
          return '即将竞价'
        }
        if (state == 2) {
          return '已成交'
        }
        if (state == 3) {
          return '已流拍'
        }
        if (state == 4) {
          return '等待结果'
        }
        if (state == 5) {
          return '公示期'
        }
      },
      
      myDTime (e) {
        if (e.outbidstate == 1) {
          return '0';
          //  return this.getRemaining(e,e.endtime);
        } else if (e.outbidstate == 3 || e.outbidstate == 2 || e.outbidstate == 4) {
          return e.endtime
        } else if (e.outbidstate == 0) {
          return e.begintime
        } else {
          return '2021-01-01'
        }
      },
      myUnit (e) {
        return e
        // if(e =='11'){
        //   return '元/吨'
        // }
        // if(e =='12'){
        //   return '元'
        // }
      }
    }
  };
</script>
<style lang="scss" scoped>
  // @import "compass/css3";
  $red: #d00;
  
  $redc: #c71622;
  $greenc: #1b8f68;
  $blankc: #8a8a8a;
  .bigbox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // justify-content:space-between;
    //   margin-top: 50px;
    background-color: #f7f7f7;
    // 禁用鼠标
    user-select: none;
    
    .item {
      background-color: #fff;
      transition: all .5s;
      width: calc((100% / 5) - 10px);
      margin: 10px 5px;
      height: 320px;
      box-sizing: border-box;
      border: 3px solid #f7f7f7;
      // border: 3px solid #f7f7f7;
      &:hover {
        border: 3px solid red;
      }
      @media (max-width: 992px) {
        width: calc((100% / 4) - 10px);
      }
    }
  }
  
  .item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 2px solid green;
    
    //   border: 2px solid green;
    
    
    .itemtitle {
      display: flex;
      width: 100%;
      height: 100%;
      flex: 4;
      //  border:3px solid yellowgreen;
      z-index: 20;
      
      & > img {
        flex: 1;
        width: 300px;
        height: 50%;
        height: 139px;
      }
    }
    
    .itembody {
      flex: 3;
      // border:3px solid yellowgreen;
    }
    
    .itemfoot {
      flex: 1;
      // border:3px solid yellowgreen;
      height: 100%;
      background: #787878;
    }
  }
  
  .itembody {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    // align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    
    & > div {
      flex: 1;
      //  border:1px solid yellowgreen;
      & > span {
        display: inline-block;
        font-weight: 500;
        font-size: 18px;
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        
        & > last-child {
          text-align: right;
        }
      }
    }
  }
  
  .itemfoot {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;
    text-align: center;
    
    & > .states {
      flex: 5;
      // height: 100%;
      // display: grid;
      // justify-content:center;
      // align-items:center;
      // border:1px solid yellowgreen;
    }
    
    & > .times {
      flex: 9;
      font-size: 12px;
      // display: grid;
      // justify-content:center;
      // align-items:center;
      // border:1px solid yellowgreen;
    }
  }
  
  .times {
    font-size: 12px;
  }
  
  .pricex {
    // margin-left: -34%;
    // text-align: left;
    & > span {
      // color: red;
    }
    
    & > :nth-child(1) {
      font-size: 12px;
      color: black;
    }
    
    & > :nth-child(2) {
      font-weight: 700;
      font-size: 12px;
    }
    
  }
  
  .numx {
    text-align: right;
    margin-right: 5%;
    
    & > span {
      font-size: 12px;
    }
    
    & > :nth-child(1) {
      font-size: 12px;
      color: black;
    }
    
    & > :nth-child(2) {
      // color: red;
    }
    
    & > :nth-child(3) {
      font-size: 12px;
      color: black;
    }
  }
  
  .redc {
    width: 37%;
    height: 100%;
    line-height: 36px;
    background: $redc !important;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  
  .greenc {
    width: 37%;
    height: 100%;
    line-height: 36px;
    background: $greenc !important;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  
  .blankc {
    width: 37%;
    height: 100%;
    line-height: 36px;
    background: $blankc !important;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  
  
  .mainsheet {
    margin-bottom: 50px;
  }
  
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    
    text-align: left;
    font-size: 18px;
    // background: rgb(168, 24, 24);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: left;
    width: 200px;
    height: 300px;
    border: 1px solid rgb(180, 180, 180);
    
    & > div {
      
      width: 100%;
      flex: 1;
      // border:1px solid red;
      &:nth-child(1) {
        border: 0;
        height: 20%;
        flex: 4;
      }
    ;
      
      &:nth-child(2) {
        
        width: 80%;
        text-decoration: underline;
        margin-left: 5px;
        white-space: nowrap; //不换行
        overflow: hidden; //超出隐藏
        text-overflow: ellipsis; //变成...
      }
    ;
      
      &:nth-child(3) {
        margin-left: 20px;
        flex: 4;
        font-size: 16px;
        
        
      }
      
    }
  }
  
  .gaizhang1 {
    // border: 2px solid green;
    position: relative;
    
    &::after {
      border: solid .2em $red;
      border-radius: 20em;
      color: $red;
      content: '已成交';
      font-size: 20px;
      font-weight: bold;
      line-height: 3;
      position: absolute;
      padding: 1.5em;
      margin: 0 auto;
      top: -50%;
      left: 25%;
      text-transform: uppercase;
      opacity: .65;
      transform: rotate(-35deg) scale(1);
      z-index: 200;
      transform-origin: 50% 50%;
      transition: all .3s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      width: 130px;
      height: 130px;
    }
    
  }
  
  .gaizhang2 {
    &::after {
      opacity: .75;
      transform: rotate(-35deg) scale(1);
      z-index: -1;
    }
  }
  
  .cgal {
    // float: left;
    display: inline-block;
    font-weight: 700;
    font-family: "Microsoft Yahei", 微软雅黑, Tahoma, Arial, Helvetica, STHeiti;
    margin-bottom: 15px;
  }
  
  .cgal2 {
    display: inline-block;
    font-weight: 500;
    font-family: "Microsoft Yahei", 微软雅黑, Tahoma, Arial, Helvetica, STHeiti;
    margin-bottom: 15px;
    // float: right;
    margin-left: 85%;
    // overflow: hidden;
  }


</style>
