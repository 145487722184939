<!--
 * @Description: 首页组件
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:36:12
 -->
<template>
  <div class="home" id="home" name="home">
    <div class="banner container">
      <div class="banner-menu">
        <div class="banner-menu-item"  v-for="(item, index) in menuList"
             @click="goSubBidding(5, index+1, item.code)" :key="index"
              v-on:mouseover="showSubmenu(item,index+1)">
          {{item.name}} <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <!--       -->
      <div class="banner-submenu" v-on:mouseleave="hideSubmenu" v-if="showSubmenuFlag" >
        <div class="banner-submenu-content">
<!--          <div >-->
<!--       -->
<!--          </div>-->
          <span class="banner-submenu-item" v-for="(item, index) in submenuList" :key="index"
                v-on:mouseover="showSubmenu2(item)" @click="goSubBidding(1, menuActive+','+index, item.code)">
            {{item.name}}
          </span>
        </div>
        <div style="flex: 1">
          <div v-if="submenuList2&&submenuList2.length>0" class="banner-submenu2">
            <i class="el-icon-arrow-right"></i>
            <span class="banner-submenu-item2" v-for="(item, index) in submenuList2"
                  :key="index"  @click="goSubBidding(4, menuActive+',0,'+index, item.code)">
            {{item.name}}
          </span>
          </div>
        </div>
      </div>
      <el-carousel height="100%" class="flex-fill banner-img">
        <el-carousel-item v-for="item in urlbanners" :key="item">
          <img :src="item.url" @click="openLink(item.outurl)" style="cursor: pointer;"/>
          <!-- <el-image  :src="item" lazy></el-image> -->
        </el-carousel-item>
        <!-- <el-carousel-item>
            <img style="width: 100%;height: 100%" src="../assets/imgs/2.jpg"/>
        </el-carousel-item> -->
      </el-carousel>
      <div class="banner-notice">
        <div class="banner-notice-title"> <svg-icon name="notice"></svg-icon> 拍卖公告 </div>
        <div class="banner-notice-box">
          <div class="banner-notice-item" v-for="(item,index) in noticeList" @click="handleClick(item)"
               :key="index">{{item.targetname}}</div>
        </div>
        <el-image :src="require('../assets/imgs/wycz02.jpg')" fit="fill" @click="goto()"></el-image>
      </div>
    </div>
    <!-- 竞拍流程 -->
    <div class="container">
      <div class="trading">
        <el-row class="trading-body" type="flex">
          <el-col :span="3" class="trading-starEnd">
            竞拍流程
          </el-col>
          <el-col :span="3">
            <div class="trading-step" @click="dialogVisiblejpdis(1)">
              <div>第一步</div>
              <div class="icon-i" >
                <svg-icon name="file"></svg-icon>
                <div class="icon-w">拍前准备</div>
              </div>
            </div>
          </el-col>
          <div class="trading-icon">
            <svg-icon name="ellipsis"></svg-icon>
          </div>
          <el-col :span="3" >
            <div class="trading-step" @click="dialogVisiblejpdis(2)">
              <div>第二步</div>
              <div class="icon-i" >
                <svg-icon name="file"></svg-icon>
                <div class="icon-w">报名参拍</div>
              </div>
            </div>
          </el-col>
          <div class="trading-icon">
            <svg-icon name="ellipsis"></svg-icon>
          </div>
          <el-col :span="3">
            <div class="trading-step" @click="dialogVisiblejpdis(3)">
              <div>第三步</div>
              <div class="icon-i" >
                <svg-icon name="file"></svg-icon>
                <div class="icon-w">出价竞拍</div>
              </div>
            </div>
          </el-col>
          <div class="trading-icon">
            <svg-icon name="ellipsis"></svg-icon>
          </div>
          <el-col :span="3" class="trading-step">
            <div class="trading-step" @click="dialogVisiblejpdis(4)">
              <div>第四步</div>
              <div class="icon-i" >
                <svg-icon name="file"></svg-icon>
                <div class="icon-w">竞拍成功</div>
              </div>
            </div>
          </el-col>
          <div class="trading-icon">
            <svg-icon name="ellipsis"></svg-icon>
          </div>
          <el-col :span="3">
            <div class="trading-step" @click="dialogVisiblejpdis(5)">
              <div>第五步</div>
              <div class="icon-i" >
                <svg-icon name="file"></svg-icon>
                <div class="icon-w">办理交割</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 轮播图END -->
    <div class="main-box pt-3">
      <div class="container">
        <div class="page-title">
          <span class="page-title-text">拍卖公告</span>
          <span class="font-size-14 cursors"  @click="opennewbidding(0)">更多>></span>
        </div>
        <!-- 拍卖公告-->
        <bdindex></bdindex>

      </div>
    </div>
    <!--<div class="container" style="user-select: none;cursor: pointer;">-->
      <!--<el-card>-->
        <!--<el-image :src="require('../assets/imgs/wycz01.jpg')" fit="fill" @click="goto()"></el-image>-->
      <!--</el-card>-->
    <!--</div>-->
    <div class="container" style="padding-top:20px; cursor: pointer; ">
      <div class="page-title">
        <span class="page-title-text">成交案例</span>
        <span class="font-size-14 cursors" @click="opennewbidding(0)">更多>></span>
      </div>
      <homedone></homedone>
    </div>

  </div>
</template>
<script>
  import {mapGetters} from "vuex";

  import bdindextemp from '../commons/bidding_index'
  import homedone from '../commons/home_done'

  export default {
    components: {
      bdindex: bdindextemp,
      homedone: homedone
    },
    data () {
      return {
        urlbanners:[],
        urlbannersTemp: [{url:require ("../assets/banner/1.jpg")}, {url:require ("../assets/banner/2.jpg")}],
        inputSearch: '',
        dialogVisiblejp: false,
        loadingzdxm: true,
        dataForm: {
          productid: '',
          delivery: '',
          outbidstate: '',
          splitstate: ''
        },

        bidpageIndex: 1,
        bidpageSize: 10,
        totalPage: 0,
        bidnames: '',//竞价场次名称
        timeDate: '',//开始结束时间
        dataBiddinglist: [],
        accountFlag: '1',
        ////////////////////////////////
        //竞买
        tableData: '',
        //煤种
        coal: '',
        coalList: [],
        //采购单位
        purchasingUnit: '',
        information: [],
        procureList: [],
        activeName: '1',
        carousel: "", // 轮播图数据
        phoneList: "", // 手机商品列表
        miTvList: "", // 小米电视商品列表
        applianceList: "", // 家电商品列表
        applianceHotList: "", //热门家电商品列表
        accessoryList: "", //配件商品列表
        accessoryHotList: "", //热门配件商品列表
        protectingShellList: "", // 保护套商品列表
        chargerList: "", //充电器商品列表
        applianceActive: 1, // 家电当前选中的商品分类
        accessoryActive: 1,// 配件当前选中的商品分类
        dataList: [],
        productshortname: '',
        pricelist: [],//最新成交列表
        pageIndex: 1,//当前页数
        pageSize: 3,//每页多少条数据
        // totalPage: 0,
        pageIndexs: 1,//竞价销售当前页数
        pageSizes: 4,//竞价销售每页多少条数据
        xfjpdateList: [],
        zdxmdateList: [],
        xfjpdateList1: [],
        xfjpdateList2: [],
        xfjpdateList3: [],
        //商城商品列表
        goodsList: [
          [
            {
              name: '气煤',
              class: '烟煤',
              specification: '含碳80%左右',
              volume: '3w5000吨',
              warehouse: '大库',
              price: '4,000.00',
            },
            {
              name: '肥煤',
              class: '烟煤',
              specification: '含碳90%左右',
              volume: '3w5000吨',
              warehouse: '中库',
              price: '9,000.00',
            },
            {
              name: '焦煤',
              class: '烟煤',
              specification: '含碳50%左右',
              volume: '3w5000吨',
              warehouse: '小库',
              price: '2,000.00',
            },
          ],
          [
            {
              name: '气煤',
              class: '烟煤',
              specification: '含碳80%左右',
              volume: '3w5000吨',
              warehouse: '大库',
              price: '4,000.00',
            },
            {
              name: '肥煤',
              class: '烟煤',
              specification: '含碳90%左右',
              volume: '3w5000吨',
              warehouse: '中库',
              price: '9,000.00',
            },
            {
              name: '焦煤',
              class: '烟煤',
              specification: '含碳50%左右',
              volume: '3w5000吨',
              warehouse: '小库',
              price: '2,000.00',
            },
          ],
          [
            {
              name: '气煤',
              class: '烟煤',
              specification: '含碳80%左右',
              volume: '3w5000吨',
              warehouse: '大库',
              price: '4,000.00',
            },
            {
              name: '肥煤',
              class: '烟煤',
              specification: '含碳90%左右',
              volume: '3w5000吨',
              warehouse: '中库',
              price: '9,000.00',
            },
            {
              name: '焦煤',
              class: '烟煤',
              specification: '含碳50%左右',
              volume: '3w5000吨',
              warehouse: '小库',
              price: '2,000.00',
            },
          ],
        ],
        homeData: {},
        tarList: {},
        priceList: {},
       // menuList: ['闲废竞拍', '煤系产品竞拍', '进场交易', '招商公告', '闲置调剂'],
        menuList: [],
        //拍卖公告list
        noticeList:[],
        showSubmenuFlag:false,
        submenuList:[],
        submenuList2:[],
        menuActive:''
      }
    },
    watch: {
      "$route.path": function (newVal, oldVal) {
        if (newVal != '/') {
          clearTimeout (this.tarList);
          clearTimeout (this.homeData);
          clearTimeout (this.priceList);
        }
      }
    },
    mounted () {
      this.getBannerList ();
      this.gethomePriceList ();
      this.getzdxmList ()
      this.getDataList ()
      this.getNewsDataList ()
      this.getNoticeList();
      this.getMenuList();
    },
    methods: {
      ...mapGetters (["getUser", "getToken"]),
      getMenuList(){
        this.$fetch ('/shoppingui/ectargettype/shoppingListNotAllAndOther', {}, true).then ((data) => {
          if (data && data.code === 0) {
            this.menuList = [...data.data,...this.menuList]
          }
        })
      },
      //展示子菜单
      showSubmenu(item, index){
        if(item.children){
          this.showSubmenuFlag = true;
          this.submenuList = item.children;
          this.menuActive = index;
        }else {
          this.showSubmenuFlag = false;
        }
        this.submenuList2 = [];
      },
      showSubmenu2(item){
        this.submenuList2 = item.children;
      },
      //隐藏子菜单
      hideSubmenu(){
        this.showSubmenuFlag = false;
      },
      goSubBidding(type,index,code){
        let routeData = this.$router.resolve({path: "/newbidding?index="+index+"&code="+code});
        window.open(routeData.href, '_blank');
      },
      // Banner图
      getBannerList () {
        this.$fetch ('/shoppingui/ecossfile/listImg', {
          'type': 0,
          'deleted': 0,
        }, true).then ((data) => {
          if (data && data.code === 0) {
            this.urlbanners = data.data;
            if (this.urlbanners.length == 0) {
              this.urlbanners = this.urlbannersTemp
            }
          } else {
            this.urlbanners = this.urlbannersTemp
          }
        })
      },
      //打开页面
      openLink(url){
        if(url){
          window.open(url);
        }
      },
      // search框
      gosearchList () {
        let queryData = {};
        this.$set (queryData, 'targetname', this.inputSearch)
        let routeData = this.$router.resolve ({path: "/HomeSearch", query: queryData});
        window.open (routeData.href, '_blank');
      },
      goto () {
        let routeData = this.$router.resolve ({path: "/management"});
        window.open (routeData.href, '_blank');
      },
      gotoWenJuan () {
        let routeData = this.$router.resolve ({path: "/wenjuan"});
        window.open (routeData.href, '_blank');
      },
      // dialog
      dialogVisiblejpdis (e) {
        console.log(e)
        let queryData = {};
        this.$set (queryData, 'id', e)
        let routeData = this.$router.resolve ({path: "/service", query: queryData});
        window.open (routeData.href, '_blank');
      },
      iteminfo (e) {
        let queryData = {};
        this.$set (queryData, 'id', e.id)
        this.$set (queryData, 'targetid', e.id)
        this.$set (queryData, 'memberid', this.getUser ().id)
        let routeData = this.$router.resolve ({path: "/auction", query: queryData});
        window.open (routeData.href, '_blank');
      },

      getNewsDataList () {
        this.$fetch ('/shoppingui/bidShopping/getNewReleasePage', {
          'page': 1,
          'limit': 3,
        }, true).then ((data) => {
          if (data && data.code === 0) {
            this.information = data.data.list;
          } else {
            this.information = []
            this.$message ({
              message: data.msg + '获取失败',
              type: 'error',
              showClose: 'true'
            })
          }
        })
      },
      // 竞价
      getDataList () {
        this.$fetch ('/shoppingui/bidShopping/targetPageList', {
          'page': 1,
          'limit': 3,
          'order': 'desc',
          // 'delivery': this.dataForm.delivery,
          // 'outbidstate': this.dataForm.outbidstate,
          // 'splitstate': this.dataForm.splitstate,
          // 'begintime': this.timeDate === ''|| this.timeDate === null ? '' :this.formateDate(this.timeDate[0]) +' ' + '00:00:00',
          // 'endtime': this.timeDate === '' || this.timeDate === null ? '':this.formateDate(this.timeDate[1]) + ' ' + '23:59:59',
          // 'bidname': this.bidnames === '' || this.bidnames === null? '':this.bidnames,
        }, true).then ((data) => {
          if (data && data.code === 0) {
            this.dataBiddinglist = data.data.list;
            this.bidtotalPage = data.data.totalCount
            // this.deptlist= data.data.list
          } else {
            this.dataBiddinglist = []
            this.bidtotalPage = 0
            this.$message ({
              message: data.msg + '获取失败',
              type: 'error',
              showClose: 'true'
            })
          }
        })
      },

      // 重点进场项目推荐
      getzdxmList () {
        this.$fetch ('/shoppingui/ectarget/getlist', {
          'page': 1,
          'keyproject': 1,
          'limit': 20,
          'order': 'desc'
        }, true).then ((data) => {
          if (data && data.code === 0) {
            const templist = data.data.list
            this.zdxmdateList = []
            this.xfjpdateList1 = []
            this.xfjpdateList2 = []
            this.xfjpdateList3 = []
            this.xfjpdateList1 = templist.slice (0, 3)
            this.xfjpdateList2 = templist.slice (3, 6)
            this.xfjpdateList3 = templist.slice (6, 9)
            this.zdxmdateList.push (this.xfjpdateList1);
            this.zdxmdateList.push (this.xfjpdateList2);
            this.zdxmdateList.push (this.xfjpdateList3);
            this.loadingzdxm = false
          } else {
            this.zdxmdateList = []
            this.totalPage = 0
            this.$message ({
              message: data.msg,
              type: 'error',
              showClose: 'true'
            })
          }
        })
      },
      //转换时间格式
      formateDate (datetime) {
        if (datetime == '' || datetime == undefined) {
          return '';
        }

        function addDateZero (num) {
          return (num < 10 ? "0" + num : num);
        }

        let d = new Date (datetime);
        let formatdatetime = d.getFullYear () + '-' + addDateZero (d.getMonth () + 1) + '-' + addDateZero (d.getDate ());
        return formatdatetime;
      },

      //跳到公告
      // getNoticelist (modes) {
      //   let queryData = {};
      //   queryData.modes = modes;
      //   this.$router.push ({path: "/NoticeList", query: queryData});
      // },
      //公告竞买
      gotNoticeProduct (name, title, ptargetid) {
        let queryData = {};
        queryData.bidname = name;
        queryData.title = title;
        queryData.ptargetid = ptargetid;
        this.$router.push ({path: "/ProductXQ", query: queryData});
      },
      // 跳转到新闻详情页
      gotoinfoDetail (name, url, title) {
        let queryData = {};
        queryData.url = url;
        queryData.newsReleaseName = name;
        queryData.title = title;
        this.$router.push ({path: "/zxXiangQing", query: queryData});
      },


      // 承诺函
      gotNoticeDetails (e) {
        let queryData = {};
        this.$set (queryData, 'id', e.id)
        this.$set (queryData, 'targetid', e.id)
        this.$set (queryData, 'memberid', this.getUser ().id)
        this.$router.push ({path: "/auction", query: queryData});
      },


      //发布公告详情页
      gotoPublicDetail (name, url) {
        let queryData = {};
        queryData.url = url;
        queryData.newsReleaseName = name;
        this.$router.push ({path: "/zxXiangQing", query: queryData});
      },
      moreAll () {
        let queryData = {};
        this.$router.push ({path: "/Chakan", query: queryData});
      },
      messageMoreAll () {
        let queryData = {};
        this.$router.push ({path: "/XiangQingList", query: queryData});
      },

      handleClick (e) {
        let queryData = {};
        this.$set (queryData, 'id', e.id)
        this.$set (queryData, 'targetid', e.id)
        this.$set (queryData, 'memberid', this.getUser ().id)
        if (e.splitstate == 0) {
          this.$router.push({path: "/auction", query: queryData});
        } else {
          this.$router.push({path: "/auction_split", query: queryData});
        }
      },
      gethomePriceList () {
        this.$fetch ('/shoppingui/bidShopping/pageList', {
          'page': this.pageIndex,
          'limit': this.pageSize,
          'bid': '1'
        }, true).then ((data) => {
          if (data && data.code === 0) {
            this.pricelist = data.data.list;
            this.priceList = setTimeout (this.gethomePriceList, 180000);
          } else {
            this.pricelist = []
            this.$message ({
              message: data.msg,
              type: 'error',
              showClose: 'true'
            })
          }
        })
      },
      gotoDetails (targetid) {
        let queryData = {};
        queryData.targetid = targetid;
        this.$router.push ({path: "/auction", query: queryData});
      },
      gotopDetails (targetid, activeNameTemp) {
        let queryData = {};
        queryData.ptargetid = targetid;
        if (activeNameTemp == '0' || activeNameTemp == '1' || activeNameTemp == '2') {
          queryData.activeName = '2';
        } else {
          queryData.activeName = activeNameTemp;
        }
        this.$router.push ({path: "/product", query: queryData});
      },
      // 获取拍卖公告
      getNoticeList(e){
        this.$fetch('/shoppingui/bidShopping/targetPageList', {
          'page': 1,
          'limit': 8,
          'outbidstate': 5,
        }, true).then((data) => {
          if (data && data.code === 0) {
            this.noticeList= data.data.list
          } else {
            this.noticeList = []
            this.$message({
              message: data.msg,
              type: 'error',
              showClose: 'true'
            })
          }
        })
      },
      // 闲废竞拍 更多 跳转
      opennewbidding(index){
        if(index ==0){        // 闲废竞拍
          let routeData = this.$router.resolve({path: "/newbidding"});
          window.open(routeData.href, '_blank');
        }else if(index ==1){        // 副产品
          let routeData = this.$router.resolve({path: "/newbidding"});
          window.open(routeData.href, '_blank');
        }
        // else if (index == 2) { // 进场交易
        //   let routeData = this.$router.resolve({path: "/JinChangJiaoYiList"});
        //   window.open(routeData.href, '_blank');
        // }else if(index == 3){        // 招商公告
        //   let routeData = this.$router.resolve({path: "/ZhaoshangList"});
        //   window.open(routeData.href, '_blank');
        // }  else if (index == 4) {        // 闲置调剂
        //   let routeData = this.$router.resolve({path: "/xianZhiTiaoJiList"});
        //   window.open(routeData.href, '_blank');
        // }
      },

    },

    //价格指数
    gethomeDataList () {
      this.$fetch ('http://47.104.185.159:8989/rbac-admin/app/priceindex-list', {
        'page': 1,
        'limit': 9999,
        'dimension': 3
      }, true).then ((data) => {
        if (data && data.code === 0) {
          this.chartData.rows.push ()
        } else {
          this.dataList = []
          this.$message ({
            message: data.msg,
            type: 'error',
            showClose: 'true'
          })
        }
      })
    },

  };
</script>
<style scoped lang="scss">
  @import "../assets/css/index.css";
  @import "../assets/css/page/home.scss";

  .search_box {
    position: absolute;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
    left: 50%;
    margin-left: -168px;
    margin-top: -6%;
    padding: 10px 12px;

    .bar1 {
      text-align: center;
      width: 100%;
      vertical-align: middle;

      input {
        vertical-align: middle;
        height: 35px;
        width: 300px;
        border: 3px solid #c2030a;
        border-radius: 5px 0 0 5px;
        background: #F9F0DA;
        color: #0c0101;
        height: 36px;
        outline: none;
        padding: 0 10px;
      }

      .button {
        vertical-align: middle;
        background: #c2030a;
        height: 36px;
        width: 36px;
        border-radius: 0 5px 5px 0;
        border: 0;
      }
    }
  }

  /deep/ .el-icon-search:before {
    display: inline-block;
    color: white;
    font-size: 18px;
    font-weight: 700;
    margin-top: -3px;
    margin-left: -10px;
  }

</style>
