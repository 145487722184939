<template>
  <div class="auction">

    <div class="auction-box">
      <div class="auction-item" v-for="(i,index) in deptlist" :key="index" @click="iteminfo(i)">
        <div class="auction-item-status" :type="i.outbidstate">{{typeName(i.outbidstate)}}</div>
        <el-image class="auction-item-img" :src="myPic(i.thumb)" fit="cover" lazy></el-image>
        <div class="auction-item-body">
          <div class="auction-item-title"><span :title="i.targetname">{{i.targetname}}</span></div>
          <div class="auction-item-text" v-if="i.shw == 1">
            <span>挂牌价：</span>
            <div class="auction-item-price">
              {{i.price}}
              <span style="font-size:12px;">{{i.unit}}</span>
            </div>
          </div>
          <div  class="auction-item-text" v-else>
            <span>挂牌价：</span>
            <span class="auction-item-price">{{i.shwword}}</span>
            <!--                            <span style="font-size:12px">{{myUnit(i.unit)}}</span>-->
          </div>
          <div class="auction-item-text" v-if="i.outbidstate==2" >
            <span>成交价：</span>
            <div class="auction-item-price">
              {{i.outbidprice}}
              <span style="font-size:12px;">{{i.unit}}</span>
            </div>
          </div>
          <div class="auction-item-place">
            {{i.areaname}}
          </div>
        </div>
        <div class="auction-item-time">
          {{myTime(i)}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapGetters} from "vuex";
  
  export default {
    data () {
      return {
        howWidth: 4,
        targettypecascader: '',
        splitstate: '0',
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        morebtn: 0,
        activeName: 'first',
        dataList: [],
        
        dataForm: {
          productid: '',
          delivery: '',
          outbidstate: '',
          splitstate: ''
        },
        // 闲废竞拍列表--副产品列表
        xfjpdateList: [],
        // 进场交易列表
        jinchangList: [],
        // 招商公告列表
        zhaoShangList: [],
        // 闲置调剂列表
        XianZhiTiaoJiList: [],
        deptlist: []
      };
    },
    activated () {
      this.getsearch ();
    },
    mounted () {
    },
    methods: {
      // 查询调用
      getsearch (e) {
        // if(e.targettypecascader.substr(0,2)!='11'){
        //   const index = e.targettypecascader.indexOf(',')
        //   e.targettypecascader = e.targettypecascader.substring(0,index)
        // }
        this.$fetch ('/shoppingui/bidShopping/targetPageList', {
          'page': 1,
          'limit': 10,
          // 'targettypecascader':e.targettypecascader,
          // 'provincecode':e.provincecode,
          // 'city': e.city,
          // 'town': e.town,
        }, true).then ((data) => {
          if (data && data.code === 0) {
            this.getNewTime ()
            this.deptlist = data.data.list
            console.log (this.deptlist)
            // this.totalPage = data.data.total
            
          } else {
            this.deptlist = []
            // this.totalPage = 0
            this.$message ({
              message: data.msg,
              type: 'error',
              showClose: 'true'
            })
          }
        })
      },
      // 获取服务器得时间
      getNewTime () {
        this.$post ("/shoppingui/bidShopping/getsysTime").then ((data) => {
          if (data.code == 0) {
            this.systemTime = new Date (data.data);
          } else {
            this.$message ({
              message: data.message,
              offset: 130,
              type: "error",
              showClose: "true",
            });
          }
        });
      },
      myPic(e){
        if(e.length >0){
          return this.$ImgUrl+e[0]
        }
        return require('../assets/imgs/zanwutupian.jpg')
      },
      iteminfo(e){
        let queryData = {};
        this.$set(queryData, 'id',e.id)
        this.$set(queryData, 'targetid',e.id)
        this.$set(queryData, 'memberid',this.getUser().id)
        if (e.splitstate == 0) {
          this.$router.push({path: "/auction", query: queryData});
        } else {
          this.$router.push({path: "/auction_split", query: queryData});
        }
      },
      myState(state){
        if(state==1){
          return  'redc'
        }
        if(state==0){
          return  'redc'
        }
        if(state==2){
          return  'orangec'
        }
        if(state==3){
          return  'blankc'
        }if(state==4){
          return  'blankc'
        }
        if(state==5){
          return  'greenc'
        }
      },
      typeName(state){
        if(state==1){
          return  '竞价中'
        }
        if(state==0){
          return  '即将竞价'
        }
        if(state==2){
          return  '已成交'
        }
        if(state==3){
          return  '已流拍'
        }if(state==4){
          return  '等待结果'
        }
        if(state==5){
          return  '公示期'
        }
      },
      myTime(i){
        if(i.outbidstate==1){
          return  '开始时间: ' + i.begintime
        }
        if(i.outbidstate==0){
          return  '开始时间: ' + i.begintime
        }
        if(i.outbidstate==2){
          return  '结束时间: ' + i.finalendtime
        }
        if(i.outbidstate==3){
          return  '结束时间: ' + i.finalendtime
        }
        if(i.outbidstate==4){
          return  '结束时间: ' + i.finalendtime
        }
        if(i.outbidstate==5){
          return  '开始时间: ' + i.begintime
        }
      },
      // arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      //   if (rowIndex % 2 === 0) {
      //     if (columnIndex === 0) {
      //       return [1, 2];
      //     } else if (columnIndex === 1) {
      //       return [0, 0];
      //     }
      //   }
      // },
      
      // objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      //   if (columnIndex === 0) {
      //     if (rowIndex % 2 === 0) {
      //       return {
      //         rowspan: 2,
      //         colspan: 1,
      //       };
      //     } else {
      //       return {
      //         rowspan: 0,
      //         colspan: 0,
      //       };
      //     }
      //   }
      // },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        // 闲废竞拍
        if (this.morebtn == 0) {// 闲废竞拍
          this.splitstate = '0';
          this.targettypecascader = '';
          this.getXianFeiList ();
        }
        // 招商公告
        else if (this.morebtn == 1) { // 招商公告
          this.getZhaoshangList ();
        }
        // 副产品
        else if (this.morebtn == 2) { // 副产品
          this.splitstate = '1';
          this.targettypecascader = '99'
          this.getXianFeiList ();
        }
        // 闲置调剂
        else if (this.morebtn == 3) { // 闲置调剂
          this.getXianZhiTiaoJiList ();
        }
        // 进场交易
        else if (this.morebtn == 5) { // 进场交易
          this.getJinChangJiaoYiList ()
        }
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        // 闲废竞拍
        if (this.morebtn == 0) {// 闲废竞拍
          this.splitstate = '0';
          this.targettypecascader = '';
          this.getXianFeiList ();
        }
        // 招商公告
        else if (this.morebtn == 1) { // 招商公告
          this.getZhaoshangList ();
        }
        // 副产品
        else if (this.morebtn == 2) { // 副产品
          this.splitstate = '1';
          this.targettypecascader = '99'
          this.getXianFeiList ();
        }
        // 闲置调剂
        else if (this.morebtn == 3) { // 闲置调剂
          this.getXianZhiTiaoJiList ();
        }
        // 进场交易
        else if (this.morebtn == 5) { // 进场交易
          this.getJinChangJiaoYiList ()
        }
      },
      ...mapGetters (["getUser", "getToken"]),
      // 闲废竞拍 更多 跳转
      opennewbidding () {
        if (this.morebtn == 0) {        // 闲废竞拍
          let routeData = this.$router.resolve ({path: "/newbidding"});
          window.open (routeData.href, '_blank');
        } else if (this.morebtn == 1) {        // 招商公告
          let routeData = this.$router.resolve ({path: "/ZhaoshangList"});
          window.open (routeData.href, '_blank');
        } else if (this.morebtn == 2) {        // 副产品
          let routeData = this.$router.resolve ({path: "/newbidding"});
          window.open (routeData.href, '_blank');
        } else if (this.morebtn == 3) {        // 闲置调剂
          let routeData = this.$router.resolve ({path: "/xianZhiTiaoJiList"});
          window.open (routeData.href, '_blank');
        } else if (this.morebtn == 5) { // 进场交易
          let routeData = this.$router.resolve ({path: "/JinChangJiaoYiList"});
          window.open (routeData.href, '_blank');
        }
      },
      // 首页闲废竞拍 跳转
      gotoDetailsrow (e) {
        let queryData = {};
        this.$set (queryData, 'id', e.id)
        this.$set (queryData, 'targetid', e.id)
        this.$set (queryData, 'memberid', this.getUser ().id)
        let routeData = this.$router.resolve ({path: "/auction", query: queryData});
        if (e.splitstate == 1) {
          routeData = this.$router.resolve ({path: "/auction_split", query: queryData});
        }
        window.open (routeData.href, '_blank');
      },
      // 首页闲废竞拍 跳转
      gotoDetails (e) {
        let queryData = {};
        this.$set (queryData, 'id', e.id)
        this.$set (queryData, 'targetid', e.id)
        this.$set (queryData, 'memberid', this.getUser ().id)
        let routeData = this.$router.resolve ({path: "/auction", query: queryData});
        window.open (routeData.href, '_blank');
      },
      // 进场交易列表
      getJinChangJiaoYiList () {
        console.log ('进场交易进阿里了')
        this.$fetch ('/shoppingui/ecentrytransaction/page', {
          'page': this.pageIndex,
          'limit': this.pageSize,
          'order': 'desc',
          'orderField': 'create_date'
        }, true).then ((data) => {
          // console.log('进场交易：》》》》》》》》》',data)
          if (data && data.code === 0) {
            this.jinchangList = data.data.list;
            //     console.log(this.jinchangList,'zhaoshangList');
            this.totalPage = data.data.total;
          } else {
            this.jinchangList = []
            this.totalPage = 0
            this.$message ({
              message: data.msg,
              type: 'error',
              showClose: 'true'
            })
          }
        })
      },
      // 招商公告列表
      getZhaoshangList () {
        this.$fetch ('/shoppingui/memberEntShopping/pageList', {
          'page': this.pageIndex,
          'limit': this.pageSize,
        }, true).then ((data) => {
          if (data && data.code === 0) {
            this.zhaoShangList = data.data.list;
            console.log (this.zhaoShangList, 'zhaoshangList');
            this.totalPage = data.data.total;
          } else {
            this.zhaoShangList = []
            this.totalPage = 0
            this.$message ({
              message: data.msg,
              type: 'error',
              showClose: 'true'
            })
          }
        })
      },
      // 闲置调剂
      getXianZhiTiaoJiList () {
        this.$fetch ('/shoppingui/bidShopping/xfpageList', {
          'page': this.pageIndex,
          'limit': this.pageSize,
        }, true).then ((data) => {
          if (data && data.code === 0) {
            this.XianZhiTiaoJiList = data.data.list;
            this.totalPage = data.data.total;
          } else {
            this.XianZhiTiaoJiList = []
            this.totalPage = 0
            this.$message ({
              message: data.msg,
              type: 'error',
              showClose: 'true'
            })
          }
        })
      },
      // 闲废竞拍--卖破烂
      getXianFeiList () {
        var params = {
          'page': this.pageIndex,
          'limit': this.pageSize,
          // 'targettypecascader': this.targettypecascader,
          'splitstate': this.splitstate,
          'order': 'desc'
        };
        this.$fetch ('/shoppingui/bidShopping/targetWasteList', params, true).then ((data) => {
          if (data && data.code === 0) {
            this.xfjpdateList = data.data.list;
            console.log (this.xfjpdateList, 'xxx');
            this.totalPage = data.data.total;
          } else {
            this.xfjpdateList = []
            this.totalPage = 0
            this.$message ({
              message: data.msg,
              type: 'error',
              showClose: 'true'
            })
          }
        })
      },
      // 切换title
      handleClick (tab, event) {
        let name = tab.name
        // 闲废竞拍
        if ('first' === name) {
          this.morebtn = 0;
          this.pageIndex = 1;
          this.pageSize = 10;
          this.totalPage = 0;
          this.splitstate = '0';
          this.targettypecascader = '';
          this.getXianFeiList ();
        }
        // 招商公告
        if ('second' === name) {
          this.morebtn = 1;
          this.pageIndex = 1;
          this.pageSize = 10;
          this.totalPage = 0;
          this.getZhaoshangList ();
        }
        // 副产品
        if ('third' === name) {
          this.pageIndex = 1;
          this.pageSize = 10;
          this.totalPage = 0;
          this.morebtn = 2;
          this.splitstate = '1';
          this.targettypecascader = '99'
          this.getXianFeiList ();
        }
        // 闲置调剂
        if ('fourth' === name) {
          this.morebtn = 3;
          this.pageIndex = 1;
          this.pageSize = 10;
          this.totalPage = 0;
          this.getXianZhiTiaoJiList ();
        }
        // 进场交易
        if ('jinchang' === name) {
          this.morebtn = 5;
          this.pageIndex = 1;
          this.pageSize = 10;
          this.totalPage = 0;
          this.getJinChangJiaoYiList ()
        }
      },
      myPrice (e) {
        return e
        // if(e=="11"){
        //   return '元/吨'
        // }if(e=="12"){
        //   return '元'
        // }
      },
      mytargetType (code) {
        if (code) {
          let listCode = code.split (",");
          console.log (listCode, 'listcode');
          let itemTil = ""; //lable
          let listCodeValue = ""; //用的code
          if (listCode) {
            if (listCode.length == 3) {
              listCodeValue = listCode[1];
            } else if (listCode.length == 2 || listCode.length == 1) {
              listCodeValue = listCode[0];
            }
            if (listCodeValue == 'feijiushebei') {
              return '废旧设备'
            } else if (listCodeValue == 'fuchanpin') {
              return '副产品'
            } else if (listCodeValue == 'fjwz') {
              return '废旧物资'
            } else if (listCodeValue == 'xianfeicar') {
              return '闲废车辆'
            } else if (listCodeValue == 'gongyegufei') {
              return '工业固废'
            } else if (listCodeValue == 'zulin') {
              return '租赁'
            } else {
              return '其他'
            }
          }
        }
        
      },
      mytargetType2 (e) {
        if (e != null) {
          if (e.substr (0, 2) == '11') {
            return '产权机构'
          } else if (e.substr (0, 2) == '22') {
            return '自行处理'
          } else if (e.substr (0, 2) == '33') {
            return '资产租赁'
          } else if (e.includes ('a')) {
            return '产权转让'
          } else if (e.includes ('b')) {
            return '资产处置'
          } else {
            return '其他'
          }
        }
      },
      // 招商公告
      gotoZhaoshangDetails (row) {
        let queryData = {};
        this.$set (queryData, 'id', row.id)
        this.$set (queryData, 'targetid', row.id)
        this.$set (queryData, 'memberid', this.getUser ().id)
        let routeData = this.$router.resolve ({path: "/zhaoshangone", query: queryData});
        window.open (routeData.href, '_blank');
      },
      // 闲置调剂
      gotoXianZhiTiaoJiDetails (row) {
        let queryData = {};
        this.$set (queryData, 'id', row.id)
        this.$set (queryData, 'targetid', row.id)
        this.$set (queryData, 'memberid', this.getUser ().id)
        let routeData = this.$router.resolve ({path: "/xianzhitiaojione", query: queryData});
        window.open (routeData.href, '_blank');
      },
      // 进场交易详情页
      gotoJinChangDetails (row) {
        let queryData = {};
        this.$set (queryData, 'id', row.id)
        this.$set (queryData, 'targetid', row.id)
        this.$set (queryData, 'memberid', this.getUser ().id)
        let routeData = this.$router.resolve ({path: "/JinChangJiaoYiOne", query: queryData});
        window.open (routeData.href, '_blank');
        
      },
      JinChangLeiXingType (val) {
        if (val == 0) {
          return '实物资产'
        } else if (val == 1) {
          return '股权'
        } else {
          return '未分类'
        }
      }
    }
  };
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../assets/css/page/bidding_index.scss";
  
  .gotoDetails {
    cursor: pointer;
  }
  
  .cgal2 {
    position: relative;
    top: 30px;
    font-weight: 500;
    font-family: "Microsoft Yahei", 微软雅黑, Tahoma, Arial, Helvetica, STHeiti;
    margin-bottom: 15px;
    margin-left: 85%;
    z-index: 20;
  }
  
  /deep/ .el-card__body {
    padding: 0 20px 20px 20px;
    z-index: 1;
  }
  
  .el-table {
    /deep/ tbody tr:hover > td {
      cursor: pointer;
      background-color: #fde8ea;
      // font-size: 18px;
      font-weight: 500;
    }
  }
</style>
